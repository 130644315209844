import React, { useState } from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";
import { isBrowser, loadGiftUp } from "../../lib/giftup";
import "./giftcard.scss";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const GiftCard = ({ data: { ribbon, header, footer } }) => {
  useState(() => {
    if (typeof window !== "undefined") {
      loadGiftUp(
        window,
        document,
        "script",
        "https://cdn.giftup.app/dist/gift-up.js",
        "giftup"
      );
    }
  }, []);

  const goToForm = () => {
    const selectFormSection = document.getElementById(
      "giftup-purchase-container"
    );
    window.scrollTo({
      top: selectFormSection.offsetTop,
      behavior: "smooth",
    });
  };
  const { t } = useTranslation();
  return (
    <Layout header={header} ribbon={ribbon} footer={footer}>
      <section className="giftcard-hero">
        <StaticImage
          src="../../images/giftcard-hero.jpg"
          alt="gift card"
          placeholder="blurred"
          layout="fullWidth"
        />
        <div className="overlayer"></div>
        <div className="over-layer-text">
          <div className="container">
            <h1>
              {t("theFitplan")}<span>{t("giftCard")}</span>
            </h1>
            <button type="button" onClick={goToForm}>{t("buyNow")}</button>
          </div>
        </div>
      </section>
      <section className="giftcard-section-works">
        <div className="container">
          <p>{t("giftupSubHeading")}</p>
          <h3>{t("howItWorks")}</h3>
          <div className="work-points-giftcard">
            <div className="gifcard-col">
              <h4>1. {t("q1")}</h4>
              <p>{t("a1")}</p>
            </div>
            <div className="gifcard-col">
              <h4>2. {t("q2")}</h4>
              <p>{t("a2")}</p>
            </div>
            <div className="gifcard-col">
              <h4>3. {t("q3")}</h4>
              <p>{t("a3")}</p>
            </div>
          </div>
          <section
            className="giftup-purchase-container"
            id="giftup-purchase-container"
          >
            <div
              className="gift-up-target"
              data-site-id="76ce3a35-4f54-4901-9f17-ff7cf23531ba"
              data-product-id="ba67ea62-142b-4913-e1d3-08d509dec75d"
              data-promo-code="PFSCARD"
              data-platform="Other"
            />
          </section>
          <p className="gift_redeem">
            {t("redeemGiftCardMessage")},{" "}
            <Link to="/giftcard/redeem">{t("redeemHere2")}.</Link>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default GiftCard;
